/*
-------------------------------------------------------------------------------
RBX.Rewards v3 CSS by Kylo Development 
Version: 0.0.1
Contacts: https://discord.gg/9gcB8NQDzG | Logan#4321 | Kyle#8855
Copyright 2022. All rights reserved.
-------------------------------------------------------------------------------
*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base */

html,
body {
	@apply h-full w-full;
	scrollbar-gutter: auto;
}

body {
	@apply bg-[#ecf7ff] text-[#2c2c2c];
	font-family: "Poppins", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.progress {
transition: width .75s;
}

.app {
	@apply min-h-[100vh];
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.container {
	@apply m-auto max-w-[1080px];
}

main.container {
	@apply m-auto py-6 px-3.5 max-w-[1080px];
}

/* Navigation */

nav.navbar a.nav-item:is(:first-child) {
	@apply pl-0;
}

nav.navbar a.nav-item:is(:last-child) {
	@apply pr-0;
}

nav.navbar {
	/* @apply bg-gradient-to-l from-[#1565c0] to-[#1e88e5]; */
}

nav.navbar a.nav-item {
	@apply inline-block py-0 px-2.5 no-underline text-center text-white leading-[3.875rem];
}

nav.navbar a.nav-item div {
	@apply text-xs uppercase font-bold leading-5;
}

nav.navbar a.nav-item i {
	@apply mt-2.5 text-[1.75rem];
}

/* Alerts */

.nav-alert {
	@apply shadow-sm text-white font-semibold text-center py-2 px-4;
}

.alert {
	@apply text-sm shadow-sm rounded-lg py-2 px-4 text-center text-white font-semibold mb-[0.313rem];
}

.alert.warning,
.nav-alert.warning {
	@apply bg-gradient-to-l from-[#f57c00] to-[#ffa726];
}

.alert.success,
.nav-alert.success {
	@apply bg-gradient-to-l from-[#00c853] to-[#33cd5f];
}

.alert.danger,
.nav-alert.danger {
	@apply bg-gradient-to-l from-[#ff5252] to-[#ff8a80];
}

.alert.info,
.nav-alert.info {
	@apply bg-gradient-to-l from-blue-600 to-blue-700;
}

/* Buttons */

.button {
	@apply inline-block font-medium cursor-pointer text-white py-2.5 px-5 rounded-lg shadow-sm border border-solid border-transparent no-underline;
}

.button.disabled, .button:disabled {
	@apply cursor-not-allowed opacity-70;
}

.button.thin {
	@apply py-[0.313rem] px-[0.938rem];
}

.button.thinner {
	@apply py-0.5 px-2.5;
}

.button.gray {
	@apply bg-zinc-300 text-black;
}


.button.primary {
	@apply bg-gradient-to-l from-[#1565c0] to-[#1e88e5];
}

.button.info {
	@apply bg-white;
}

.button.warning {
	@apply bg-gradient-to-l from-[#f57c00] to-[#ffa726];
}

.button.success {
	@apply bg-gradient-to-l from-[#00c853] to-[#33cd5f];
}

.button.danger {
	@apply bg-gradient-to-l from-[#ff5252] to-[#ff8a80];
}

.button:hover:not([disabled]) {
	transition: filter 0.1s;
	filter: brightness(90%);
}

/* Forms */

.form {
	@apply block w-full rounded-lg outline-none shadow-inner py-1.5 px-5 border border-solid border-[#c5c5c5];
}

/* form.lg make the form larger */
.form.lg {
	@apply py-2.5 px-5 text-xl;
}

.form:disabled {
	@apply border-transparent cursor-not-allowed;
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='32' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 50%;
  background-size: 2rem;
}

/* Cards */

.card.discord,
.card img.discord {
	@apply bg-[#7289DA];
}

.card.twitter,
.card img.twitter {
	@apply bg-[#1DA1F2];
}

.card.youtube,
.card img.youtube {
	@apply bg-[#FF0000];
}

.card.instagram {
	@apply bg-cover bg-[url('/public/assets/img/social/ig-gradient.jpg')];
}

.card img.instagram {
	@apply bg-transparent;
}

.card {
	@apply rounded-lg shadow-sm bg-white border border-solid border-[#c5c5c5];
}

.card .body {
	@apply p-4;
}

/* Tabs */

.card.has-tabs {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}

.tabs {
	@apply -ml-[0.063rem] -mt-[2.688rem]
}

.tabs .tab-item {
	@apply cursor-pointer inline-block rounded-none py-2 px-4 bg-white border border-solid border-[#c5c5c5];
	border-top-left-radius: 4px !important;
	border-top-right-radius: 4px !important;
}

.tabs .tab-item.active {
	@apply border-t-2 font-semibold border-b-white border-t-[#1e88e5];
}

/* Badges */

.badge {
	@apply absolute top-1 right-1 text-white p-1 rounded-lg text-sm font-semibold;
}

.badge.red {
	@apply bg-red-500;
}

.badge.blue {
	@apply bg-blue-500;
}

.badge.green {
	@apply bg-green-500;
}

.badge.orange {
	@apply bg-orange-500;
}

.badge.yellow {
	@apply bg-yellow-500;
}

.badge.purple {
	@apply bg-purple-500;
}

/* Offerwall Page */

.wall-adgate {
	@apply bg-gradient-to-r from-blue-500 to-cyan-500;
}

.wall-offertoro {
	@apply bg-gradient-to-r from-slate-700 to-gray-600;
}

.wall-cpxresearch {
	@apply bg-gradient-to-r from-blue-600 to-cyan-500;
}

.wall-adgem {
	@apply bg-gradient-to-r from-fuchsia-300 to-fuchsia-200;
}

.wall-lootably {
	@apply bg-gradient-to-r from-cyan-600 to-cyan-400;
}

.wall-ayetstudios {
	@apply bg-gradient-to-r from-slate-400 to-slate-300;
}

.offerwall-iframe {
	@apply h-[100vh];
}

@media screen and (min-width: 600px) {
	.offerwall-iframe {
		@apply h-[65vh];
	}
}

/* Footer */

footer {
	@apply bg-white sticky top-full border-t border-solid border-t-[#00000033];
}

/* Hamburger Menu */
.hamburger {
	@apply cursor-pointer w-6 h-6 relative transform transition-all duration-[250ms];
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
	@apply absolute top-0 left-0 w-6 h-0.5 transform rotate-0 bg-white transition-all duration-500;
}

.hamburger-middle {
	@apply transform translate-y-[0.438rem];
}

.hamburger-bottom {
	@apply transform translate-y-[0.875rem];
}

.open {
	@apply transform rotate-90 translate-y-0;
}

.open .hamburger-top {
	@apply transform rotate-45 translate-y-2.5 -translate-x-0.5;
}

.open .hamburger-middle {
	@apply hidden;
}

.open .hamburger-bottom {
	@apply transform -rotate-45 translate-y-2.5 -translate-x-0.5;
}

/* Tables */

table {
	@apply bg-zinc-200 w-full rounded-md overflow-hidden;
}

table tr {
	@apply border-b border-solid border-b-[#c5c5c5];
}

th,
td {
	@apply pt-2 pb-2 pl-3 pr-3;
}

th,
tr {
	@apply text-center;
}

td {
	@apply break-words text-ellipsis overflow-hidden;
}

table .success {
	@apply bg-green-300;
}

table .danger {
	@apply bg-red-300;
}

/* Modals */

.modal {
	@apply fixed z-50 w-full h-full top-0 left-0 items-center justify-center;
}

.modal.show {
	@apply flex;
}

.modal-content {
	@apply bg-white w-11/12 md:max-w-md mx-auto rounded z-50 overflow-y-auto;
	animation: fadeIn 0.3s ease-in;
	animation-fill-mode: forwards;
}

@keyframes fadeIn {
	from {
		@apply opacity-0;
		transform: translateY(20px);
	}

	to {
		@apply opacity-100 transform translate-y-0;
	}
}

/* Lists */

ul li:before {
	content: '➤';
	@apply ml-0 mr-2.5;
}

/* Miscellaneous */

.section-div {
	@apply m-0 uppercase text-center font-bold flex select-none text-sm text-[#777];
}

.section-div::before,
.section-div::after {
	@apply inline-block flex-1 self-center bg-[#555] my-0 mx-[0.438rem] h-[0.063rem];
	content: "";
}

.visually-hidden {
	position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important;
}

/* Giveaways */

/* Help Center */

.active .help-nav {
	@apply !border-l-[0.3rem] !border-solid !border-l-blue-500 font-semibold;
}

/* Landing */

#animated-bg {
	@apply bg-repeat bg-[url('/public/assets/img/landing.jpg')];
    background-position-y: 85%!important;
    background-position-x: 0;
    animation: animated-bg 500s linear infinite;
}

@keyframes animated-bg {
    from {
        background-position: 0 0
    }

    to {
        background-position: -10000px 0
    }
}

.spinner-grow {
	vertical-align: -0.125em;
    animation: .75s ease-in-out infinite _spinner-grow;
}

@keyframes _spinner-grow{0%{transform:scale(0)}50%{opacity:1;transform:none}}

.fa-spin-fix .fa-spin {
    -webkit-transform-origin: 50% calc(50% - .5px)!important;
    transform-origin: 50% calc(50% - .5px)!important;
}